<template>
  <b-modal
    id="modal_add_emergencia"
    v-model="dialog"
    :title="vamosCrear ? 'Registrar nueva emergencia' : 'Editar emergencia'"
    title-class="text-white"
    ok-only
    size="lg"
    :header-bg-variant="vamosCrear ? 'primary' : 'warning'"
    hide-footer
    no-close-on-esc
    no-close-on-backdrop
    hide-header-close
  >

    <!-- BODY -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <!-- Form -->
      <b-form
        class="p-2"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <validation-provider
          #default="validationContext"
          name="servicio"
          rules="required"
        >
          <b-form-group
            label="Nombres y Apellidos"
            label-for="servicio"
          >
            <b-form-input
              v-model="editedItem.nombres"
              :state="getValidationState(validationContext)"
            />
            <b-form-invalid-feedback v-if="validationContext.errors[0]">
              Este campo es requerido
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <b-row>
          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="servicio"
              rules="required"
            >
              <b-form-group
                label="Sexo: "
                label-for="servicio"
              >
                <b-form-select
                  v-model="editedItem.sexo"
                  :options="optionsSexo"
                  :state="getValidationState(validationContext)"
                />

                <b-form-invalid-feedback v-if="validationContext.errors[0]">
                  Este campo es requerido
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="servicio"
              rules="required"
            >
              <b-form-group
                label="Edad: "
                label-for="edad"
              >
                <b-form-input
                  v-model="editedItem.edad"
                  :state="getValidationState(validationContext)"
                />

                <b-form-invalid-feedback v-if="validationContext.errors[0]">
                  Este campo es requerido
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <validation-provider
          #default="validationContext"
          name="servicio"
          rules="required"
        >
          <b-form-group
            label="Es estudiante / docente / administrativo: "
            label-for="servicio"
          >
            <b-form-select
              v-model="editedItem.tipo_paciente"
              :options="optionsPaciente"
              :state="getValidationState(validationContext)"
            />

            <b-form-invalid-feedback v-if="validationContext.errors[0]">
              Este campo es requerido
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- <validation-provider
          #default="validationContext"
          name="servicio"
          rules="required"
        > -->
        <b-form-group
          label="Oficina"
          label-for="servicio"
        >
          <b-form-input
            v-model="editedItem.oficina"
            :disabled="editedItem.tipo_paciente === 'Estudiante' ? true : false"
          />

          <!-- <b-form-invalid-feedback v-if="validationContext.errors[0]">
              Este campo es requerido
            </b-form-invalid-feedback> -->
        </b-form-group>
        <!-- </validation-provider> -->

        <validation-provider
          #default="validationContext"
          name="servicio"
          rules="required"
        >
          <b-form-group
            label="Tipo de emergencia"
            label-for="servicio"
          >
            <b-form-select
              v-model="editedItem.tipo_emergencia"
              :options="optionsEmergencia"
              :state="getValidationState(validationContext)"
            />

            <b-form-invalid-feedback v-if="validationContext.errors[0]">
              Este campo es requerido
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <validation-provider
          #default="validationContext"
          name="servicio"
          rules="required"
        >
          <b-form-group
            label="Fecha de atención"
            label-for="servicio"
          >
            <b-form-input
              v-model="editedItem.fecha_atencion"
              type="date"
              :state="getValidationState(validationContext)"
            />
            <b-form-invalid-feedback v-if="validationContext.errors[0]">
              Este campo es requerido
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <validation-provider
          #default="validationContext"
          name="accion"
          rules="required"
        >
          <b-form-group
            label="Descripción"
            label-for="accion"
          >
            <b-form-textarea
              v-model="editedItem.descripcion"
              rows="3"
              max-rows="6"
              :state="getValidationState(validationContext)"
              placeholder="Describa aquí.."
            />
            <b-form-invalid-feedback v-if="validationContext.errors[0]">
              Este campo es requerido
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- Form Actions -->
        <div class="d-flex justify-content-between ">
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            @click="closeModal"
          >
            <feather-icon
              icon="XIcon"
              size="14"
              class="mr-50"
            />
            <span class="align-middle">Cancelar</span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            :variant="vamosCrear ? 'primary' : 'warning'"
            type="submit"
          >
            <feather-icon
              :icon="vamosCrear ? 'SaveIcon' : 'RefreshCcwIcon'"
              size="14"
              class="mr-50"
            />
            <span class="align-middle">{{ vamosCrear ? 'Guardar' : 'Actualizar' }}</span>
          </b-button>
        </div>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>

import store from '@/store'
import {
  BButton, BForm, BFormGroup, BFormInvalidFeedback, BFormInput, BFormSelect, BFormTextarea,
  BRow,
  BCol,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import { avatarText } from '@core/utils/filter'

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BButton,
    BFormGroup,
    BFormSelect,
    BFormTextarea,
    BFormInput,
    BFormInvalidFeedback,
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    Ripple,
  },
  props: {
    vamosCrear: {
      type: Boolean,
      default: true,
    },
    dialog: {
      type: Boolean,
      default: false,
    },
    editedItem: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      optionsPaciente: ['Estudiante', 'Docente', 'Administrativo', 'Otro'],
      optionsEmergencia: ['Heridas', 'Hemorragias o Sangrado', 'Intoxicaciones', 'Quemaduras', 'Fiebre', 'Picaduras y Mordeduras de Animales', 'Convulsiones', 'Atragantamiento', 'Traumatismo', 'Reanimacion cardio pulmonar RCP', 'Transporte o Trasalado de heridos', 'Otro'],
      optionsSexo: ['Masculino', 'Femenino', 'No definido'],
      avatarText,
      required,
      nombreBotonModal: '',
    }
  },
  methods: {
    closeModal() {
      this.$emit('update:dialog', false)
    },
    onSubmit() {
      if (this.vamosCrear) {
        this.createEmergencia()
      } else {
        this.updateEmergencia()
      }
    },
    createEmergencia() {
      store.dispatch('emergencias/addEmergencia', this.editedItem)
        .then(() => {
          this.$emit('actualizar-tabla')
          this.toastSuccess()
          this.closeModal()
        }).catch(() => {
          this.toastError()
        })
    },
    updateEmergencia() {
      store.dispatch('emergencias/updateEmergencia', this.editedItem)
        .then(() => {
          this.$emit('actualizar-tabla')
          this.toastSuccess()
          this.closeModal()
        }).catch(() => {
          this.toastError()
        })
    },
  },
  setup() {
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    return {

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },

}
</script>

<style lang="scss" scoped>
</style>
