import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchEmergencias(ctx, { queryParams }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/servicio-salud/emergencia', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addEmergencia(ctx, data) {
      return new Promise((resolve, reject) => {
        axios.post('/servicio-salud/emergencia', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateEmergencia(ctx, data) {
      return new Promise((resolve, reject) => {
        axios.put(`/servicio-salud/emergencia/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteEmergencia(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/servicio-salud/emergencia/${data.id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // Servicios
    // fetchServicios() {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .get('/salud/tipos/servicios')
    //       .then(response => resolve(response))
    //       .catch(error => reject(error))
    //   })
    // },

  },
}
